 var stickyHeader = true;
// var flyoutNav = false;
// var fullscreenNav = false;
// var hoverSensitiveNav = false;
// var megaMenu = false;

// var carouselBackgroundVideoYouTubeID = ''; // see https://fresco-docs.netlify.com/#/carousel/background-video

var countUpStats = true;
var countUpStatsDuration = 3000;

var homeQuickGivingPreFooter = true;
// var transformHomepageDonationAmountImages = false;

// var feedTitleLinks = ['/first-feed-title-link', '/second-feed-title-link']; // see https://fresco-docs.netlify.com/#/home-feeds/feed-title-links
// var feedItemsCarouselBreakpoint = 576; // see https://fresco-docs.netlify.com/#/home-feeds/feed-items-carousel
// var feedItemsCarouselAutoplay = true;
// var feedItemsCarouselAutoplaySpeed = 4000;

// var subsiteHijackMainNav = ['example-subsite-name'];
// var subsiteHijackMainLogoClickDestination = ['example-subsite-name'];
// var subsiteTitleNotClickable = true;

var lualBodyClasses = ["sendastar", "together-for-christmas", "gingerbread-christmas"];
var visualAppealsLoadMore = true;
var visualAppealsCustomHeightValues = {
  "gingerbread-christmas": {
    heightSm: "-605",
    heightMd: "-1100",
    heightXl: "-1450"
  } 
}

// Nav items open in new tab
$('.topLevel li a[href*="http"]').each(function() {
  $(this).prop('target', '_blank');
});

// Custom feed layout
// $('.homeFeed:not(.homeFeedBox1').wrapAll('<div class="feed-container">');

$('.footerBox[class*="ogos"]').insertAfter('.pageFooterWrapper');

// Event
$(".listedEvent:not(:has([class*='image-link']))").addClass("listedEventNoBanner");


// Stats - With Icon
$(".homefeaturecategory-homeboximpactstats td:has(img)").addClass("containsIcon");



// ===========
// Old bespoke
// ===========

//  Change totaliser labels
$('.totaliserLabel').each(function () {
  $$ = $(this);
  if ($$.text() == 'Raised so far') $$.text('Raised');
});

// //  Move headerText div outside the carousel
// if ($('.carousel .headerText').length > 0) {
//   $('.carousel').each(function () {
//     $thisHeaderText = $(this).find('.headerText');
//     $(this).parent().append($thisHeaderText);
//   });
// }

$(".giftAidStatement")
  .first()
  .html(
    "I want to Gift Aid donations made today and any donations I make in the future or have made in the past 4 years. I am a UK taxpayer and understand that if I pay less income tax and/or Capital Gains Tax than the amount of Gift Aid claimed on all my donations in that tax year it is my responsibility to pay any difference."
  );

$("body.donate.christmaswishes div.donationComment > label").text(
  "Send a Christmas wish to the children of Haven House"
);

$('body[class*="sendastar"] .donationComment label').text('Please leave a message on your star and we will share it with our families');


